<template>
  <div class="account-write-off">
    <el-row>
      <el-col :span="6">
        <el-input
          class="search-input"
          :suffix-icon="Search"
          v-model="formData.keywords"
          placeholder="请输入客人姓名进行搜索"
          @keyup.enter="handleSearch"
          clearable
          @clear="handleSearch"
        />
      </el-col>
      <el-col :span="10">
        <el-row>
          <el-col :span="2" class="text-center"> </el-col>
          <el-col :span="10">
            <el-date-picker
              :clearable="true"
              v-model="formData.start_time"
              type="date"
              placeholder="挂账时间开始"
              @change="changeStime"
            />
          </el-col>
          <el-col :span="2" class="text-center">
            <span>-</span>
          </el-col>
          <el-col :span="10">
            <el-date-picker
              :clearable="true"
              v-model="formData.end_time"
              type="date"
              placeholder="挂账时间结束"
              @change="changeEtime"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="3"> </el-col>
      <el-col :span="5" class="text-right">
        <div class="btn" @click="handleCheckOff">挂账核销</div>
        <el-tooltip class="item" effect="dark" content="导出" placement="left">
          <span class="border-icon" @click="handleExport">
            <i class="iconfont icon-a-lujing207"></i
          ></span>
        </el-tooltip>

        <el-tooltip class="item" effect="dark" content="打印" placement="left">
          <span class="border-icon mr-10" @click="handlePrint">
            <i class="iconfont icon-a-lujing206"></i
          ></span>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table
      class="ar-table"
      v-loading="loading"
      :data="tableData"
      height="360"
      style="width: 100%"
      stripe
      tooltip-effect="light"
      @selection-change="handleSelectionChange"
      :row-key="getRowKeys"
      ref="tableRef"
    >
      <el-table-column type="selection" :reserve-selection="true" width="55" />
      <el-table-column property="sn" label="支付订单号">
        <template #default="scope">
          <span>
            {{ scope.row.sn }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="tourist" label="客人姓名">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.tourist }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="money" label="挂账金额">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.money }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="time" label="挂账时间">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.time }}
          </span></template
        >
      </el-table-column>
      <el-table-column
        property="ar_remark"
        label="挂账备注"
        :show-overflow-tooltip="true"
        width="180"
      >
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.ar_remark }}
          </span></template
        >
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        v-model:currentPage="listQuery.page"
        v-model:page-size="listQuery.limit"
        :page-sizes="[20, 50, 100, 200, 500]"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="listQuery.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentPageChange"
      >
      </el-pagination>
    </div>
    <CheckOffDialog
      ref="checkOffDialogRef"
      @changeSelect="changeSelect"
      @submit="submitCheckOff"
    ></CheckOffDialog>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { VipApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { exportExcel, print } from "@/utils/common.js";
import { Search } from "@element-plus/icons-vue";
import CheckOffDialog from "./CheckOffDialog.vue";
import dayjs from "dayjs";
export default {
  props: {
    detailId: {
      type: Number,
      default: 0,
    },
  },
  components: { CheckOffDialog },
  setup(props) {
    // { emit }
    const listQuery = ref({
      page: 1,
      limit: 20,
      total: 0,
    });
    const formData = ref({
      keywords: "",
      start_time: "",
      end_time: "",
    });
    function changeStime(data) {
      if (
        formData.value["end_time"] &&
        dayjs(formData.value["end_time"]).isBefore(dayjs(data))
      ) {
        //如果结束时间有值 且 在开始时间之前
        formData.value["start_time"] = formData.value["end_time"];
        formData.value["end_time"] = data;
      } else {
        formData.value["start_time"] = data;
      }
      fetchData();
    }
    function changeEtime(data) {
      if (
        formData.value["start_time"] &&
        dayjs(formData.value["start_time"]).isAfter(dayjs(data))
      ) {
        formData.value["end_time"] = formData.value["start_time"];
        formData.value["start_time"] = data;
      } else {
        formData.value["end_time"] = data;
      }
      fetchData();
    }
    const tableData = ref([]);
    const allData = ref([]);
    const loading = ref(false);
    function handleSearch() {
      listQuery.value.page = 1;
      fetchData();
    }
    function getRowKeys(row) {
      return row.sn;
    }

    function fetchData() {
      const lastParmas = {
        req_type: 1,
        ar_id: props.detailId,
        ...formData.value,
      };
      loading.value = true;
      VipApi.accountDetail(lastParmas).then((res) => {
        loading.value = false;
        if (res.Code === 200) {
          allData.value = res.Data.list;
          let lastData = JSON.parse(JSON.stringify(allData.value));
          if (formData.value.keywords) {
            lastData = lastData.filter((item) =>
              item.tourist.includes(formData.value.keywords)
            );
          }
          if (formData.value["start_time"] && formData.value["end_time"]) {
            lastData = lastData.filter(
              (item) =>
                dayjs(item.ctime).isBefore(dayjs(formData.value["end_time"])) &&
                dayjs(item.ctime).isAfter(dayjs(formData.value["start_time"]))
            );
          } else if (formData.value["start_time"]) {
            lastData = lastData.filter((item) => {
              return dayjs(item.ctime).isAfter(
                dayjs(formData.value["start_time"])
              );
            });
          } else if (formData.value["end_time"]) {
            lastData = lastData.filter((item) =>
              dayjs(item.ctime).isBefore(dayjs(formData.value["end_time"]))
            );
          }
          tableData.value = lastData.slice(
            (listQuery.value.page - 1) * listQuery.value.limit,
            listQuery.value.page * listQuery.value.limit
          );
          listQuery.value.total = lastData.length;
        } else {
          let msg = res.Message ? res.Message : "获取数据失败！";
          ElMessage.error(msg);
        }
      });
    }
    onMounted(() => {
      fetchData();
    });
    function handleSizeChange(val) {
      if (val && listQuery.value.limit !== val) {
        listQuery.value.limit = val;
      }
      fetchData();
    }
    function handleCurrentPageChange(p) {
      if (p && listQuery.value.page !== p) {
        listQuery.value.page = p;
      }
      fetchData();
    }
    const selectData = ref([]);
    function handleSelectionChange(data) {
      selectData.value = data;
    }
    const tableRef = ref(null);
    function clearSelection() {
      tableRef.value.clearSelection();
    }
    function changeSelect(data) {
      clearSelection();
      data.length &&
        data.forEach((el) => {
          tableRef.value.toggleRowSelection(el, true);
        });
    }
    const checkOffDialogRef = ref(null);
    function handleCheckOff() {
      if (selectData.value.length) {
        checkOffDialogRef.value.openDialog(selectData.value);
      } else {
        ElMessage.warning("请先选择需要核销的订单！");
      }
    }
    function submitCheckOff(data) {
      const snIds = [];
      selectData.value.length &&
        selectData.value.forEach((el) => {
          snIds.push(el.sn);
        });
      const lastParmas = {
        ar_id: props.detailId,
        ...data,
        sn: snIds.length ? snIds.join(",") : "",
      };
      VipApi.checkOrder(lastParmas).then((res) => {
        if (res.Code === 200) {
          ElMessage.success("核销成功！");
          checkOffDialogRef.value.closeDialog();
          checkOffDialogRef.value.closeDialogLoading();
          fetchData();
        } else {
          checkOffDialogRef.value.closeDialogLoading();
          let msg = res.Message ? res.Message : "核销失败！";
          ElMessage.error(msg);
        }
      });
    }
    const propertiesConfig = ref([
      { field: "sn", displayName: "支付订单号" },
      { field: "tourist", displayName: "客人姓名" },
      { field: "money", displayName: "挂账金额" },
      { field: "time", displayName: "挂账时间" },
      { field: "ar_remark", displayName: "挂账备注" },
    ]);
    function handlePrint() {
      print("账户核销", allData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel("账户核销", allData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    return {
      tableData,
      loading,
      handleSizeChange,
      handleCurrentPageChange,
      listQuery,
      handleExport,
      handlePrint,
      formData,
      changeStime,
      changeEtime,
      Search,
      handleSelectionChange,
      fetchData,
      handleSearch,
      getRowKeys,
      checkOffDialogRef,
      handleCheckOff,
      clearSelection,
      tableRef,
      selectData,
      changeSelect,
      submitCheckOff,
    };
  },
};
</script>

<style lang="scss">
.account-write-off {
}
</style>
