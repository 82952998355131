<template>
  <div class="account-dialog">
    <w-dialog
      ref="dialogRef"
      :title="formData.ar_id ? '编辑账户' : '新增账户'"
      width="50%"
      btnWidth="140px"
      top="20vh"
      :confirmText="formData.ar_id ? '确认编辑' : '确认新增'"
      @wConfirm="handleSure"
    >
      <el-form
        class="add-form"
        ref="formRef"
        :model="formData"
        :rules="formRules"
        labelPosition="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="选择类别" prop="ar_artid">
              <el-select v-model="formData.ar_artid" placeholder="请选择类别">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in typeOptions"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item> </el-col
          ><el-col :span="12">
            <el-form-item label="账户名称" prop="ar_name">
              <el-input
                v-model="formData.ar_name"
                clearable
                placeholder="请输入账户名称"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="账户限额" prop="ar_money">
              <el-input
                type="number"
                v-model="formData.ar_money"
                clearable
                placeholder="请输入账户限额"
              ></el-input>
            </el-form-item> </el-col
          ><el-col :span="12">
            <el-form-item label="有效期起止" prop="useDate">
              <el-date-picker
                v-model="formData.useDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :clearable="false"
              >
              </el-date-picker></el-form-item
          ></el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="联系人" prop="ar_contract">
              <el-input
                v-model="formData.ar_contract"
                clearable
                placeholder="请输入联系人"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="ar_mobile">
              <el-input
                v-model="formData.ar_mobile"
                placeholder="请输入联系电话"
                clearable
              ></el-input>
            </el-form-item>
          </el-col> </el-row
        ><el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注" prop="ar_remark">
              <el-input
                v-model="formData.ar_remark"
                clearable
                placeholder="请输入备注"
                :rows="3"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import { ElMessage } from "element-plus";
import { VipApi } from "@/plugins/api.js";
import dayjs from "dayjs";
import { checkStr } from "@/utils/common.js";

export default {
  emits: ["submit"],
  setup(props, { emit }) {
    const formData = ref({
      ar_artid: "",
      ar_name: "",
      ar_money: "",
      useDate: [],
      ar_contract: "",
      ar_mobile: "",
      ar_remark: "",
    });

    const typeOptions = ref([]);
    const dialogRef = ref(null);
    const formRules = reactive({
      ar_artid: [
        {
          required: true,
          message: "请选择类别",
          trigger: "change",
        },
      ],
      ar_name: [
        {
          required: true,
          message: "请输入账户名称",
          trigger: "blur",
        },
      ],
      ar_money: [
        {
          required: true,
          message: "请输入账户限额",
          trigger: "blur",
        },
      ],
      useDate: [
        {
          required: true,
          message: "请选择账户有效期",
          trigger: "change",
        },
      ],
    });
    function getAccountTypeOptions() {
      VipApi.accountTypeOptions().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            typeOptions.value = res.Data;
          }
        } else {
          let msg = res.Message ? res.Message : "获取账户类型数据失败！";
          ElMessage.error(msg);
        }
      });
    }
    function openDialog(data) {
      getAccountTypeOptions();
      if (data && data.ar_id) {
        //编辑

        formData.value = {
          ar_artid: data.ar_artid,
          ar_name: data.ar_name,
          ar_money: data.ar_money,
          useDate: [data.ar_start_time, data.ar_end_time],
          ar_contract: data.ar_contract,
          ar_mobile: data.ar_mobile,
          ar_remark: data.ar_remark,
          ar_id: data.ar_id,
        };
      } else {
        // 新增
        formData.value = {
          ar_artid: "",
          ar_name: "",
          ar_money: "",
          useDate: [],
          ar_contract: "",
          ar_mobile: "",
          ar_remark: "",
        };
      }
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    const formRef = ref(null);
    function handleSure() {
      if (formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            dialogRef.value.isLoading = true;
            // const data = JSON.parse(JSON.stringify(formData.value));
            const lastData = {
              ar_start_time: dayjs(formData.value.useDate[0]).format(
                "YYYY-MM-DD"
              ),
              ar_end_time: dayjs(formData.value.useDate[1]).format(
                "YYYY-MM-DD"
              ),
              ar_artid: formData.value.ar_artid,
              ar_name: formData.value.ar_name,
              ar_money: formData.value.ar_money,
              ar_contract: formData.value.ar_contract,
              ar_mobile: formData.value.ar_mobile,
              ar_remark: formData.value.ar_remark,
            };
            if (lastData.ar_mobile && !checkStr(lastData.ar_mobile, "phone")) {
              ElMessage.warning("联系电话格式错误！");
              dialogRef.value.isLoading = false;
              return false;
            }
            if (formData.value.ar_id) {
              lastData["ar_id"] = formData.value.ar_id;
            }
            emit("submit", lastData);
          }
        });
      }
    }
    return {
      formData,
      formRules,
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      typeOptions,
    };
  },
};
</script>

<style lang="scss">
.account-dialog {
  .tips {
    font-size: 12px;
    color: var(--text-gray-color);
  }
}
</style>
