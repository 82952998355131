<template>
  <div class="ar-container main-cnt">
    <div class="title">AR列表</div>
    <div class="content">
      <common-table
        ref="tableRef"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :filters="filters"
        :ispaging="true"
        :needExport="true"
        :needPrint="true"
        :apiName="VipApi.arList"
        :columns="tableColumns"
        @edit="openAccountDialog"
        @statusChange="changeAccountStatus"
        @export="handleExport"
        @print="handlePrint"
      >
        <template #ar_name="{ row }">
          <span class="text-operation" @click="openAccountDetailDialog(row)">{{
            row.ar_name
          }}</span>
        </template>
        <template #operate>
          <el-button type="primary" round @click="openAccountDialog">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增账户</el-button
          >
        </template>
      </common-table>
    </div>
    <AccountDialog
      ref="accountDialogRef"
      @submit="submitAccount"
    ></AccountDialog>
    <AccountDetailDialog
      ref="accountDetailDialogRef"
      @reloadTable="reloadTable"
    ></AccountDetailDialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { VipApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { exportExcel, print } from "@/utils/common.js";
import AccountDialog from "../components/AccountDialog.vue";
import AccountDetailDialog from "../components/AccountDetailDialog.vue";

onMounted(() => {
  filters.value[1].value = [];
  reloadTable();
  VipApi.accountTypeOptions().then((res) => {
    filters.value[1].options = res.Data;
    // res.Data.length &&
    //   res.Data.forEach((el) => {
    //     filters.value[1].value.push(el.id);
    //   });
  });
  VipApi.accountStatusOptions().then((res) => {
    filters.value[2].options = res.Data;
  });
});

const filters = ref([
  {
    filterType: "search",
    name: "keywords",
    value: "",
    placeholder: "请输入AR账户进行搜索",
  },
  {
    filterType: "select",
    name: "art_id",
    value: [],
    placeholder: "请选择账户类型",
    val: "id",
    lab: "name",
    isMultiple: true,
    options: [],
  },
  {
    filterType: "select",
    name: "status",
    value: 1,
    placeholder: "请选择账户状态",
    options: [],
    val: "id",
    lab: "name",
  },
]);
/** 表格对象 */
const tableRef = ref(null);

/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "ar_artid",
    label: "账户ID",
    color: "--text-third-color",
    minWidth: 50,
  },
  {
    prop: "ar_name",
    label: "账户名称",
    type: "customRender",
    minWidth: 300,
  },
  {
    prop: "art_name",
    label: "账户类型",
    color: "--text-third-color",
  },
  {
    prop: "ar_status",
    label: "账户状态",
    token: "n_sU4jwM0XKiQEES9sJdJrhQKvjaz",
    type: "switch",
    minWidth: 50,
  },

  {
    prop: "expire_at_txt",
    label: "有效期起止",
    color: "--text-third-color",
    minWidth: 120,
  },
  {
    prop: "ar_money",
    color: "--text-third-color",
    label: "账户限额",
  },
  {
    prop: "all_check_money",
    color: "--text-third-color",
    label: "挂账总额",
  },

  {
    prop: "had_check_money",
    color: "--text-third-color",
    label: "已核销总额",
  },
  {
    prop: "need_check_money",
    color: "--text-third-color",
    label: "待核销总额",
  },
  {
    prop: "ar_remark",
    label: "备注",
    color: "--text-third-color",
    minWidth: 100,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "m_mx9wiI3QYNfu0g7T8h2tUJCZaAKO",
      },
    ],
  },
]);
function reloadTable() {
  tableRef.value.tableLoad();
}
function changeAccountStatus(row) {
  let data = {
    ar_id: row.ar_id,
    ar_status: row.ar_status == 1 ? 2 : 1,
  };
  VipApi.setArStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("操作成功！");
      reloadTable();
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
const accountDialogRef = ref(null);
const openAccountDialog = (row) => {
  const rowData = row.ar_id ? row : {};
  accountDialogRef.value.openDialog(rowData);
};
function submitAccount(data) {
  const url = data.ar_id ? "editAr" : "addAr";
  VipApi[url](data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("操作成功！");
      accountDialogRef.value.closeDialog();
      accountDialogRef.value.closeDialogLoading();
      reloadTable();
    } else {
      accountDialogRef.value.closeDialogLoading();
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
const accountDetailDialogRef = ref(null);
function openAccountDetailDialog(row) {
  accountDetailDialogRef.value.openDialog(row);
}
const propertiesConfig = ref([
  { field: "ar_artid", displayName: "账户ID" },
  { field: "ar_name", displayName: "账户名称" },
  { field: "art_name", displayName: "账户类型" },
  { field: "ar_status_txt", displayName: "账户状态" },
  { field: "expire_at_txt", displayName: "有效期起止" },
  { field: "ar_money", displayName: "账户限额" },
  { field: "all_check_money", displayName: "挂账总额" },
  { field: "had_check_money", displayName: "已核销总额" },
  { field: "need_check_money", displayName: "待核销总额" },
  { field: "ar_remark", displayName: "备注" },
]);
function processData(data) {
  // data.length &&
  //   data.forEach((el) => {
  //     el.status_text = payStatusOptions.value[el.pay_status];
  //   });
  return data;
}
function handlePrint(data) {
  print("AR列表", processData(data), propertiesConfig.value);
}
function handleExport(data) {
  try {
    exportExcel("AR列表", processData(data), propertiesConfig.value);
    ElMessage.success({
      message: "导出成功！",
    });
  } catch (e) {
    ElMessage.error({
      message: "导出失败！",
    });
  }
}
</script>
<style lang="scss">
.ar-container {
  font-family: "Source Han Sans CN";
  .content {
    padding: 15px 20px 20px;
  }
  .vip-dialog {
    .el-dialog {
      min-width: 600px;
      .el-dialog__body {
        padding: 30px;
        .el-divider--horizontal {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
