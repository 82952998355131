<template>
  <div class="account-detail-dialog">
    <w-dialog
      ref="detailDialogRef"
      title="账户详情"
      width="70%"
      btnWidth="140px"
      top="5vh"
      :hideFooter="true"
      class="account-detail"
      @iconClose="iconClose"
    >
      <el-descriptions :column="3" class="basic-info" border>
        <el-descriptions-item
          v-for="(el, index) in basicInfo"
          :key="index"
          :label="el.label"
        >
          <span
            :style="{
              color: el.color ? el.color[el.value] : '',
            }"
            >{{ el.text ? el.text[el.value] : el.value }}</span
          ></el-descriptions-item
        >
      </el-descriptions>
      <el-tabs v-model="activeName" @tab-click="tabClick" type="border-card">
        <el-tab-pane label="账户核销" name="1">
          <AccountWriteOffTable
            v-if="activeName == '1'"
            ref="accountWriteOffTableRef"
            :detailId="+arId"
          ></AccountWriteOffTable>
        </el-tab-pane>
        <el-tab-pane label="核销记录" name="2">
          <WriteOffRecordTable
            v-if="activeName == '2'"
            :detailId="arId"
            @viewDetail="viewDetail"
          ></WriteOffRecordTable>
        </el-tab-pane>
        <el-tab-pane label="账户明细" name="3">
          <AccountDetailTable
            v-if="activeName == '3'"
            :detailId="arId"
            :writeOffId="writeOffId"
          ></AccountDetailTable>
        </el-tab-pane>
      </el-tabs>
    </w-dialog>
  </div>
</template>

<script>
import { ref, computed, watch, nextTick } from "vue";
import { useStore } from "vuex";
import AccountWriteOffTable from "./AccountWriteOffTable.vue";
import WriteOffRecordTable from "./WriteOffRecordTable.vue";
import AccountDetailTable from "./AccountDetailTable.vue";

export default {
  emits: ["reloadTable"],
  components: { AccountWriteOffTable, WriteOffRecordTable, AccountDetailTable },
  setup(props, { emit }) {
    const detailDialogRef = ref(null);
    const basicInfo = ref({});
    const detailInfo = ref({});
    const store = useStore();
    const menuTokens = computed(() => store.state.menuToken.menuTokens);
    const authData = ref([]);

    watch(
      () => menuTokens.value,
      (data) => {
        if (data.length) {
          authData.value = data;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const arId = ref(null);
    function openDialog(row) {
      arId.value = row.ar_id;
      activeName.value = "1";
      getDetail(row);
    }
    function closeDialog() {
      detailDialogRef.value.close();
    }
    function getDetail(detailInfo) {
      basicInfo.value = [
        {
          label: "账户名称",
          value: detailInfo.ar_name ? detailInfo.ar_name : "-",
        },
        {
          label: "账户类型",
          value: detailInfo.art_name ? detailInfo.art_name : "-",
        },
        {
          label: "账户状态",
          value: detailInfo.ar_status ? detailInfo.ar_status : "-",
          color: ["", "rgb(103, 194, 58)", "rgb(245, 108, 108)"],
          text: ["", "启用", "禁用"],
        },
        {
          label: "联系人信息",
          value: detailInfo.ar_contract
            ? detailInfo.ar_mobile
              ? detailInfo.ar_contract + "(" + detailInfo.ar_mobile + ")"
              : detailInfo.ar_contract
            : "-",
        },
        {
          label: "有效期限",
          value: detailInfo.expire_at_txt ? detailInfo.expire_at_txt : "-",
        },
        {
          label: "挂账总额",
          value: detailInfo.all_check_money
            ? detailInfo.all_check_money + "元"
            : "-",
        },
        {
          label: "已核销金额",
          value: detailInfo.had_check_money
            ? detailInfo.had_check_money + "元"
            : "-",
        },
        {
          label: "待核销金额",
          value: detailInfo.need_check_money
            ? detailInfo.need_check_money + "元"
            : "-",
        },
        {
          label: "账户限额",
          value: detailInfo.ar_money ? detailInfo.ar_money + "元" : "-",
        },
        {
          label: "备注",
          value: detailInfo.ar_remark ? detailInfo.ar_remark : "-",
        },
      ];

      detailDialogRef.value.show();
      nextTick(() => {
        activeName.value == "1" &&
          accountWriteOffTableRef.value.clearSelection();
      });
    }
    const activeName = ref("1");
    const accountWriteOffTableRef = ref(null);
    function tabClick(tab) {
      writeOffId.value = "";
      nextTick(() => {
        tab.props.name == "1" && accountWriteOffTableRef.value.clearSelection();
      });
    }
    const writeOffId = ref("");
    function viewDetail(val) {
      activeName.value = "3";
      writeOffId.value = val + "";
    }
    function iconClose() {
      emit("reloadTable", true);
    }
    return {
      closeDialog,
      basicInfo,
      detailDialogRef,
      openDialog,
      detailInfo,
      authData,
      activeName,
      tabClick,
      accountWriteOffTableRef,
      viewDetail,
      writeOffId,
      arId,
      iconClose,
    };
  },
};
</script>

<style lang="scss">
.account-detail-dialog {
  .account-detail {
    .basic-info {
      border-radius: 4px;
      margin-bottom: 30px;
      padding: 16px 30px 12px 30px;
      background-color: var(--search-uncheck-bg-color);
      .el-descriptions__body {
        border: none;
        background-color: var(--search-uncheck-bg-color);
        .el-descriptions__label,
        .el-descriptions__content {
          border: 1px solid transparent;
          background-color: var(--search-uncheck-bg-color);
        }
        .el-descriptions__cell {
          padding: 0 0 4px 0;
        }
        .el-descriptions__label {
          width: 90px;
          color: var(--text-gray-color);
          font-weight: normal;
          text-align: right;
          padding-right: 6px;
        }

        .el-descriptions__content {
          color: var(--text-color);
          min-width: 100px;
        }
      }
    }
  }
}
</style>
