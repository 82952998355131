<template>
  <div class="check-off-dialog">
    <w-dialog
      ref="dialogRef"
      title="挂账核销"
      width="50%"
      btnWidth="140px"
      top="10vh"
      confirmText="核销"
      @wConfirm="handleSure"
    >
      <el-scrollbar max-height="200px" class="select-box">
        <el-tag
          v-for="item in selectData"
          :key="item"
          size="large"
          closable
          @close="removeSelected(item.id)"
          >{{ item.sn }} {{ item.tourist }}</el-tag
        >
      </el-scrollbar>
      <br />
      <el-form
        class="add-form"
        ref="formRef"
        :model="formData"
        :rules="formRules"
        labelPosition="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="核销金额" prop="money">
              <el-input
                type="number"
                v-model="formData.money"
                clearable
                disabled
                placeholder="请输入核销金额"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="核销日期" prop="date">
              <el-date-picker
                :clearable="false"
                v-model="formData.date"
                type="date"
                placeholder="请选择核销日期"
              /> </el-form-item
          ></el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="核销方式" prop="wayIds">
              <el-select
                @change="changeWay"
                v-model="formData.wayIds"
                multiple
                placeholder="请选择核销方式"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in wayOptions"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="formData.wayIds.length">
          <el-col :span="6" v-for="el in formData.check_way" :key="el.id">
            <el-form-item label=" ">
              <el-input
                type="number"
                v-model="el.money"
                clearable
                placeholder="请输入金额"
              >
                <template #prepend>{{ el.name }}</template>
              </el-input>
            </el-form-item>
          </el-col> </el-row
        ><el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="核销备注" prop="remark">
              <el-input
                v-model="formData.remark"
                placeholder="请输入核销备注"
                :rows="3"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { VipApi } from "@/plugins/api.js";
import dayjs from "dayjs";
export default {
  emits: ["submit", "changeSelect"],
  setup(props, { emit }) {
    const formData = ref({
      money: "",
      date: "",
      wayIds: [],
      remark: "",
      check_way: [],
    });
    const wayOptions = ref([]);
    onMounted(() => {
      VipApi.writeOffOptions().then((res) => {
        if (res.Code === 200) {
          wayOptions.value = res.Data;
        } else {
          let msg = res.Message ? res.Message : "获取数据失败！";
          ElMessage.error(msg);
        }
      });
    });
    function changeWay(data) {
      formData.value.check_way = [];
      wayOptions.value.length &&
        wayOptions.value.forEach((el) => {
          if (data.indexOf(el.id) != -1) {
            formData.value.check_way.push({
              ...el,
              money: null,
            });
          }
        });
    }
    const dialogRef = ref(null);
    const formRules = reactive({
      money: [
        {
          required: true,
          message: "请输入核销金额",
          trigger: "blur",
        },
      ],
      date: [
        {
          required: true,
          message: "请输入核销日期",
          trigger: "change",
        },
      ],
      wayIds: [
        {
          required: true,
          message: "请选择核销方式",
          trigger: "change",
        },
      ],
    });
    const selectData = ref([]);
    function roundNumber(num) {
      return Math.round(+num * 100) / 100;
    }
    function openDialog(data) {
      selectData.value = data;
      let readMoney = 0;
      data.length &&
        data.forEach((el) => {
          readMoney += +el.money;
        });
      formData.value = {
        money: roundNumber(readMoney),
        date: "",
        wayIds: [],
        remark: "",
        check_way: [],
      };
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    function removeSelected(id) {
      const index = selectData.value.findIndex((item) => item.id == id);
      if (index != -1) {
        selectData.value.splice(index, 1);
        emit("changeSelect", selectData.value);
      }
      if (!selectData.value.length) {
        closeDialog();
      }
    }

    const formRef = ref(null);
    function handleSure() {
      if (formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            dialogRef.value.isLoading = true;
            const data = JSON.parse(JSON.stringify(formData.value));
            const lastCheckWay = [];
            for (let k = 0; k < data.check_way.length; k++) {
              if (!data.check_way[k].money) {
                ElMessage.warning(
                  `请输入核销方式为${data.check_way[k].name}的金额！`
                );
                closeDialogLoading();
                return false;
              }
              lastCheckWay.push({
                id: data.check_way[k].id,
                money: data.check_way[k].money,
              });
            }
            const lastParmas = {
              date: dayjs(data.date).format("YYYY-MM-DD"),
              check_way: lastCheckWay,
              money: data.money,
              remark: data.remark,
            };
            emit("submit", lastParmas);
          }
        });
      }
    }
    return {
      formData,
      formRules,
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      wayOptions,
      selectData,
      removeSelected,
      changeWay,
    };
  },
};
</script>

<style lang="scss">
.check-off-dialog {
  .el-input-group__append,
  .el-input-group__prepend {
    border: none;
    background-color: var(--search-uncheck-bg-color) !important;
    color: var(--text-gray-color);
  }
  .el-input-group__append {
    border-left: 1px solid #e6e3e3;
  }
  .el-input-group__prepend {
    border-right: 1px solid #e6e3e3;
  }
  .el-scrollbar__view {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after {
      content: "";
      width: 30%;
      height: 0px;
      visibility: hidden;
    }
    .el-tag {
      width: 30%;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--theme-color);
      background-color: var(--search-bg-color);

      .el-tag__close {
        color: var(--theme-color);
        right: 0px;
        &:hover {
          /* background-color: var(--theme-color);
          color: var(--text-white-color); */
        }
      }
    }
  }
}
</style>
