<template>
  <div class="account-detail-table">
    <el-row>
      <el-col :span="6">
        <el-input
          class="search-input"
          :suffix-icon="Search"
          v-model="formData.keywords"
          placeholder="请输入客人姓名、核销单号进行搜索"
          @keyup.enter="handleSearch"
          clearable
          @clear="handleSearch"
        />
      </el-col>
      <el-col :span="10">
        <el-row>
          <el-col :span="2" class="text-center"> </el-col>
          <el-col :span="10">
            <el-date-picker
              :clearable="true"
              v-model="formData.stime"
              type="date"
              placeholder="挂账时间开始"
              @change="changeStime"
            />
          </el-col>
          <el-col :span="2" class="text-center">
            <span>-</span>
          </el-col>
          <el-col :span="10">
            <el-date-picker
              :clearable="true"
              v-model="formData.etime"
              type="date"
              placeholder="挂账时间结束"
              @change="changeEtime"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="1"></el-col>
      <el-col :span="3">
        <el-select
          v-model="formData.status"
          @change="handleSearch"
          placeholder="请选择状态"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in statusOptions"
            :key="item.value"
          ></el-option> </el-select
      ></el-col>
      <el-col :span="4" class="text-right">
        <el-tooltip class="item" effect="dark" content="导出" placement="left">
          <span class="border-icon" @click="handleExport">
            <i class="iconfont icon-a-lujing207"></i
          ></span>
        </el-tooltip>

        <el-tooltip class="item" effect="dark" content="打印" placement="left">
          <span class="border-icon mr-10" @click="handlePrint">
            <i class="iconfont icon-a-lujing206"></i
          ></span>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table
      class="ar-table"
      v-loading="loading"
      :data="tableData"
      tooltip-effect="light"
      stripe
      height="360"
      style="width: 100%"
    >
      <el-table-column property="tourist" label="客人姓名">
        <template #default="scope">
          <span>
            {{ scope.row.tourist }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="money" label="挂账金额">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.money }}
          </span></template
        >
      </el-table-column>

      <el-table-column property="time" label="挂账时间">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.time }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        property="ar_remark"
        label="挂账备注"
        :show-overflow-tooltip="true"
        width="180"
      >
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.ar_remark }}
          </span>
        </template>
      </el-table-column>
      <el-table-column property="arc_money" label="核销金额">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.arc_money }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="arc_check_way_txt" label="核销方式">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.arc_check_way_txt }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="arc_date" label="核销日期">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.arc_date }}
          </span></template
        >
      </el-table-column>
      <el-table-column
        property="arc_remark"
        label="核销备注"
        :show-overflow-tooltip="true"
        width="180"
      >
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.arc_remark }}
          </span></template
        >
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        v-model:currentPage="listQuery.page"
        v-model:page-size="listQuery.limit"
        :page-sizes="[20, 50, 100, 200, 500]"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="listQuery.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentPageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { ref, watch, nextTick } from "vue";
import { VipApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { exportExcel, print } from "@/utils/common.js";
import dayjs from "dayjs";
import { Search } from "@element-plus/icons-vue";

export default {
  props: {
    writeOffId: {
      type: String,
      default: "",
    },
    detailId: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const listQuery = ref({
      page: 1,
      limit: 20,
      total: 0,
    });
    const formData = ref({
      stime: "",
      etime: "",
      keywords: "",
      status: 1,
    });
    watch(
      () => props.writeOffId,
      (val) => {
        nextTick(() => {
          formData.value.keywords = val;
          handleSearch();
        });
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const statusOptions = ref([
      {
        label: "已核销",
        value: 1,
      },
      {
        label: "待核销",
        value: 2,
      },
      {
        label: "已失效",
        value: 3,
      },
    ]);
    function changeStime(data) {
      if (
        formData.value["etime"] &&
        dayjs(formData.value["etime"]).isBefore(dayjs(data))
      ) {
        //如果结束时间有值 且 在开始时间之前
        formData.value["stime"] = formData.value["etime"];
        formData.value["etime"] = data;
      } else {
        formData.value["stime"] = data;
      }
      fetchData();
    }
    function changeEtime(data) {
      if (
        formData.value["stime"] &&
        dayjs(formData.value["stime"]).isAfter(dayjs(data))
      ) {
        formData.value["etime"] = formData.value["stime"];
        formData.value["stime"] = data;
      } else {
        formData.value["etime"] = data;
      }
      fetchData();
    }
    function handleSearch() {
      listQuery.value.page = 1;
      fetchData();
    }
    const tableData = ref([]);

    const loading = ref(false);
    function fetchData() {
      const lastParmas = {
        ar_id: props.detailId,
        req_type: 3,
        page: listQuery.value.page,
        limit: listQuery.value.limit,
        keywords: formData.value.keywords,
        status: formData.value.status,
        start_time: formData.value["stime"]
          ? dayjs(formData.value["stime"]).format("YYYY-MM-DD")
          : "",
        end_time: formData.value["etime"]
          ? dayjs(formData.value["etime"]).format("YYYY-MM-DD")
          : "",
      };
      loading.value = true;
      VipApi.accountDetail(lastParmas).then((res) => {
        loading.value = false;
        if (res.Code === 200) {
          tableData.value = res.Data.list;
          listQuery.value.total = res.Data.count;
        } else {
          let msg = res.Message ? res.Message : "获取数据失败！";
          ElMessage.error(msg);
        }
      });
    }
    function handleSizeChange(val) {
      listQuery.value.limit = val;
      fetchData();
    }
    function handleCurrentPageChange(p) {
      listQuery.value.page = p;
      fetchData();
    }

    const propertiesConfig = ref([
      { field: "tourist", displayName: "客人姓名" },
      { field: "money", displayName: "挂账金额" },
      { field: "time", displayName: "挂账时间" },
      { field: "ar_remark", displayName: "挂账备注" },
      { field: "arc_money", displayName: "核销金额" },
      { field: "arc_check_way_txt", displayName: "核销方式" },
      { field: "arc_date", displayName: "核销日期" },
      { field: "arc_remark", displayName: "核销备注" },
    ]);

    function handlePrint() {
      print("账户明细", tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel("账户明细", tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    return {
      tableData,
      loading,
      handleSizeChange,
      handleCurrentPageChange,
      listQuery,
      handleExport,
      handlePrint,
      formData,
      changeStime,
      changeEtime,
      Search,
      handleSearch,
      statusOptions,
      fetchData,
    };
  },
};
</script>

<style lang="scss">
</style>
